import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Icon from './Icon';
import PropTypes from "prop-types";

const ProgressBarItem = ({ competitionId, dates, lastImportDate, material, missingFirstPlaceFormatted, missingNextTresholdFormatted, position, prize, score, resultsDate, title1, title2, turnover, link }) => {
  const { t } = useTranslation();
  let prizeLiteral = '';
  let turnoverInfo = t('turnoverInfo');

  if (competitionId === 'materialPrize' && missingNextTresholdFormatted) {
    if (prize > 0) {
      prizeLiteral = t('tooNextPrizeMissing');
    } else {
      prizeLiteral = t('tooPrizeMissing');
    }
  }

  if (competitionId === 'materialPrize') {
    turnoverInfo = t('turnoverInfoBody');
  } else if (competitionId === 'materialPrizeCatalog') {
    turnoverInfo = t('turnoverInfoCatalog');
  }

  return (
        <div className={`mt-1 ${link ? 'md:w-1/2' : ''}`}>
          <p className="text-base text-gray">{dates}</p>
          <h3 className="mb-5 font-semibold text-3xl lg:text-40px">{title1}<br/>{title2}</h3>
          <p className="mb-2 font-semibold text-base">{t('yourResult')}</p>
          <p className="text-gray text-xs font-medium">
            {resultsDate && <span className="block lg:inline">{t('statusAsOfDay')}: {resultsDate}</span>}
            {lastImportDate && <span className="lg:ml-2">{t('lastUpdatedResults')}: {lastImportDate}</span>}
          </p>
          <div className="flex flex-col xs:flex-row md:flex-col lg:flex-row justify-between my-5 xs:px-5 md:px-0 lg:px-10 py-5 text-xs border-t border-t-progressBarBorder border-b border-b-progressBarBorder border-solid">
            <div className="flex flex-row xs:flex-col md:flex-row lg:flex-col items-baseline xs:items-center md:items-baseline lg:items-center text-center mb-2 lg:mb-0">
              <Icon id="chart" viewBox="0 0 20 20" width="20" height="20" clsWrap="relative top-1 lg:top-0 xs:mb-5 md:mb-0 lg:mb-5 mr-4 xs:mr-0 md:mr-4 lg:mr-0" />
              {t('workshopTurnover')}
              <span className="mt-2 ml-4 xs:ml-0 md:ml-4 lg:ml-0 font-bold text-lg whitespace-nowrap">{turnover}</span>
            </div>
            <div className="w-px bg-progressBarBorder mx-4"></div>
            <div className={`flex flex-row xs:flex-col md:flex-row lg:flex-col items-baseline xs:items-center md:items-baseline lg:items-center text-center ${prize > 0 ? 'text-customGreen' : 'text-red'}`}>
              <Icon id={prize > 0 ? 'trophyGreen' : 'trophy'} viewBox="0 0 20 20" width="20" height="20" clsWrap="relative top-1.5 lg:top-0 xs:mb-5 md:mb-0 lg:mb-5 mr-4 xs:mr-0 md:mr-4 lg:mr-0" />
              {t('positionInTheCompetition')}
              <span className="lg:mt-2 ml-4 xs:ml-0 md:ml-4 lg:ml-0 font-bold text-lg">{position !== null ? position : '-'}</span>
            </div>
            <div className="w-px bg-progressBarBorder mx-4"></div>
            {material ?
              <>
                {competitionId === 'materialPrizeCatalog' ?
                  <div className="flex flex-row xs:flex-col md:flex-row lg:flex-col items-baseline xs:items-center md:items-baseline lg:items-center text-left xs:text-center md:text-left lg:text-center">
                    <Icon id="chartArrow" viewBox="0 0 20 12" width="20" height="12" clsWrap="lg:mt-2 xs:mb-5 md:mb-0 lg:mb-5 mr-4 xs:mr-0 md:mr-4 lg:mr-0" />
                    {missingFirstPlaceFormatted ? t('missingFirstPlace') : t('missingNextTreshold')}
                    <span className="mt-2 ml-4 xs:ml-0 md:ml-4 lg:ml-0 font-bold text-lg whitespace-nowrap">
                      {(!missingFirstPlaceFormatted && !missingNextTresholdFormatted) ?
                        '-'
                      :
                        missingFirstPlaceFormatted ? missingFirstPlaceFormatted : missingNextTresholdFormatted
                      }
                    </span>
                  </div>
                :
                  <div className="flex flex-row xs:flex-col md:flex-row lg:flex-col items-baseline xs:items-center md:items-baseline lg:items-center text-left xs:text-center md:text-left lg:text-center">
                    <Icon id="chartArrow" viewBox="0 0 20 12" width="20" height="12" clsWrap="lg:mt-2 xs:mb-5 md:mb-0 lg:mb-5 mr-4 xs:mr-0 md:mr-4 lg:mr-0" />
                    {missingFirstPlaceFormatted ? t('missingFirstPlace') : prizeLiteral}
                    <span className="mt-2 ml-4 xs:ml-0 md:ml-4 lg:ml-0 font-bold text-lg whitespace-nowrap">
                      {(!missingFirstPlaceFormatted && !missingNextTresholdFormatted) ?
                        '-'
                      :
                        missingFirstPlaceFormatted ? missingFirstPlaceFormatted : missingNextTresholdFormatted
                      }
                    </span>
                  </div>
                }
              </>
            :
              <div className="flex flex-row xs:flex-col md:flex-row lg:flex-col items-baseline xs:items-center md:items-baseline lg:items-center text-center">
                <Icon id="prize" viewBox="0 0 25 24" width="25" height="24" clsWrap="relative top-2 lg:top-0 xs:mb-4 md:mb-0 lg:mb-4 mr-4 xs:mr-0 md:mr-4 lg:mr-0 fill-white" />
                {t('PointsEarned')}
                <span className="mt-2 ml-4 xs:ml-0 md:ml-4 lg:ml-0 font-bold text-lg whitespace-nowrap">{score}</span>
              </div>
            }
          </div>
          <p className="text-gray text-xs font-medium">{turnoverInfo}</p>
          {link &&
            <>
              <Link to={`/competition/${competitionId}`} className="flex mt-5 font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand hover:stroke-brand">
                {t('competitionDetails')}
                <Icon id="arrRight" viewBox="0 0 24 14" width="24" height="14" clsWrap="ml-4" />
              </Link>
              <p className="mt-2 text-xs font-medium">{t('previousResultInfo')}</p>
            </>
          }
        </div>
    )
}

ProgressBarItem.defaultProps = {
  dates: '',
  position: '',
  result: '',
  title: '',
  toFirst: '',
  link: true
}

ProgressBarItem.propTypes = {
  dates: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  result: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  toFirst: PropTypes.string.isRequired,
  link: PropTypes.bool,
}

export default ProgressBarItem;